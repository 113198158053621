<template>
    <div class="page">
        <div class="uk-card uk-card-default uk-card-body ">
            <div
                class="projects-card-head uk-margin-bottom  uk-flex uk-flex-between uk-flex-bottom uk-gap-m uk-flex-wrap">
                <div class="uk-card-title uk-flex uk-flex-column ">

                    <h1>
                        <slot name="pageTitle"><span>{{ translate('sgirot.project.mainTitle') }}</span></slot>
                    </h1>
                    <div class="expert-commission-summary uk-padding-small uk-background-muted" v-if="expertCommissionData">
                        <strong v-if="isSuperAdmin">{{ translate('sgirot.project.expertSummaryDueCommissionTitleForAdmin') }}</strong>
                        <strong v-if="isExpert">{{ translate('sgirot.project.expertSummaryDueCommissionTitleForExpert') }}</strong>
                        <br/>
                        <strong
                            style="width: 120px;display:inline-block;">{{
                                translate('sgirot.project.expertSummaryDueCommission')
                            }}: </strong><strong>{{ translateNumber(expertCommissionData.dueCommission, 'decimal') }}</strong>
                        <br/>
                        <strong
                            style="width: 120px;display:inline-block;">{{
                                translate('sgirot.project.expertSummaryPayedCommission')
                            }}: </strong><strong>{{ translateNumber(expertCommissionData.paidCommission , 'decimal') }}</strong>
                        <br/>
                        <strong
                            style="width: 120px;display:inline-block;">{{
                                translate('sgirot.project.expertSummaryTotalCommission')
                            }}: </strong><strong>{{ translateNumber(expertCommissionData.totalCommission , 'decimal') }}</strong>
                    </div>

                </div>

                <div class="graphs uk-flex uk-flex-wrap "
                     :style="graphsDynamicStyle"
                     style="">
                    <spinner v-if="asyncOps.asyncStatus.asyncDataLoading"
                             :text="translate('sgirot.graphs.graphsLoading')"></spinner>

                    <div class="graph-unit uk-flex uk-flex-column ">
                        <h4 class="uk-margin-remove-bottom">{{ translate('sgirot.graphs.projectGraphTitle') }}</h4>
                        <span>{{ translate('sgirot.graphs.thisMonthsProjects') }}: {{
                                lastMonthSummaryData.totalCount
                            }}</span>
                        <div class="graph-block"

                             style="width: 300px; height: 150px;"
                             ref="totalProjectsGraph"></div>
                    </div>
                    <div class="graph-unit uk-flex uk-flex-column ">
                        <h4 class="uk-margin-remove-bottom">{{ translate('sgirot.graphs.commissionGraphTitle') }}</h4>
                        <span>{{
                                translate('sgirot.graphs.thisMonthsCommission')
                            }}: {{ lastMonthSummaryData.dueCommission }}</span>

                        <div class="graph-block"
                             style="width: 300px; height: 150px;"
                             ref="totalCommissionGraph"></div>
                    </div>
                </div>
                <div class="uk-flex uk-gap-s uk-flex-wrap">
                    <div
                        class="header-action header-action--show-graphs uk-flex uk-flex-column uk-flex-center uk-flex-middle"
                        style="background-color: var(--global-secondary-background);"
                        @click="triggerShowGraphsOnMobile" v-if="isMobile && showGraphsOnMobile === false">
                        <i uk-icon="image"></i>
                        <span class="uk-text-small">{{ translate('sgirot.project.showGraphs') }}</span>
                    </div>
                    <div
                        class="header-action header-action--hide-graphs uk-flex uk-flex-column uk-flex-center uk-flex-middle"
                        style="background-color: var(--global-secondary-background);"
                        @click="showGraphsOnMobile = false" v-if="isMobile && showGraphsOnMobile === true">
                        <i uk-icon="image"></i>
                        <span class="uk-text-small">{{ translate('sgirot.project.hideGraphs') }}</span>
                    </div>
                    <div class="header-action header-action--today uk-flex uk-flex-column uk-flex-center uk-flex-middle"
                         style="background-color: var(--global-secondary-background);"
                         @click="showTodaysProjects()">
                        <i uk-icon="calendar"></i>
                        <span class="uk-text-small">{{ translate('sgirot.project.today') }}</span>
                    </div>
                    <div
                        class="header-action header-action--tomorrow uk-flex uk-flex-column uk-flex-center uk-flex-middle"

                        @click="showTomorrowsProjects()">
                        <i uk-icon="calendar"></i>
                        <span class="uk-text-small">{{ translate('sgirot.project.tomorrow') }}</span>
                    </div>
                    <div class="header-action header-action--today uk-flex uk-flex-column uk-flex-center uk-flex-middle"
                         style="background-color: var(--global-success-color);"
                         @click="clearFilters()">
                        <i uk-icon="check"></i>
                        <span class="uk-text-small">{{ translate('sgirot.project.clearFilters') }}</span>
                    </div>
                </div>
            </div>

            <div class="page-content">
                <crud-list
                    :enableIdColumn="false"
                    :blocksOnMobile="true"
                    :collapsableFiltersOnMobile=" ! isExpert"
                    @list:filtersUpdated="handleListFiltersUpdated"
                    @list:dataUpdated="handleListDataUpdated"
                    ref="crudList"
                    :enableMassActions="isSuperAdmin || isExpert"
                    :subject="entityType"
                    :tableColumns="tableColumns"
                    :filterFields="filterFields">
                    <template v-slot:rowActions="{item}">
                        <div class="item-action uk-flex uk-flex-column uk-flex-middle uk-flex-center"
                             @click="$router.push({name: 'project-itemId', params: {itemId : item.id}})">
                            <i class="" uk-icon="icon:info;ratio: 0.8"></i>
                            <span class="item-action__text">{{ translate('sgirot.general.view') }}</span>
                        </div>
                        <div class="item-action uk-flex uk-flex-column uk-flex-middle uk-flex-center"
                             v-if="isSuperAdmin"
                             @click.prevent()="copyProject(item.id)">
                            <i class="" uk-icon="icon: copy-page-reverse;ratio: 0.8"></i>
                            <span class="item-action__text">{{ translate('sgirot.project.copyProject') }}</span>
                        </div>
                    </template>

                    <template v-slot:massActions="{selectedItems : items}">
                        <form-button type="secondary"
                                     v-if="isSuperAdmin"
                                     icon="forward"
                                     @click="exportItemsCsv(items)">{{ translate('sgirot.general.export') }}
                        </form-button>
                        <form-button type="danger"
                                     v-if="isSuperAdmin"
                                     icon="trash"
                                     @click="promptDeleteItems(items)">{{ translate('sgirot.general.deleteSelected') }}
                        </form-button>
                        <form-input v-bind="statusInputConf"
                                    v-if="isSuperAdmin || isExpert"
                                    @change="massSetStatus(items)" v-model="massStatusValue"></form-input>
                    </template>

                    <template v-slot:mobileListItem="{item:item}">
                        <div class="item-data">
                            <div class="imc__head">
                                <a href="#"
                                   @click.prevent="showItem(item);">{{
                                        getCellValue('description', item.description)
                                    }}</a>
                            </div>
                            <div class="imc__details">
                                <div class="imc__detail">
                                    <span class="imc__detail-label">
                                        {{ translate('sgirot.project.mobileListCellTitleClientPhoneNumber') }}
                                    </span>
                                    <div class="imc__detail-separator">:</div>
                                    <span class="imc__detail-content">
                                        {{ getCellValue('clientPhoneNumber', item.clientPhoneNumber) }}
                                    </span>
                                </div>
                                <div class="imc__detail">
                                     <span class="imc__detail-label">
                                         {{ translate('sgirot.project.mobileListCellTitleDueDate') }}
                                    </span>
                                    <div class="imc__detail-separator">:</div>
                                    <span class="imc__detail-content">
                                         {{ getCellValue('dueDate', item.dueDate) }}
                                    </span>
                                </div>
                                <div class="imc__detail">
                                    <strong class="imc__detail-label">
                                        {{ translate('sgirot.project.mobileListCellTitlePrice') }}
                                    </strong>
                                    <div class="imc__detail-separator">:</div>
                                    <strong class="imc__detail-content">
                                        {{ getCellValue('price', item.price) }}
                                    </strong>
                                </div>
                                <div class="imc__detail imc__detail--status">
                                    <status-pill
                                        style="cursor: pointer;"
                                        @click="editProjectStatus(item)"
                                        :status="item.status"></status-pill>
                                </div>

                            </div>

                            <!--
                            <div  v-for="(col, colIndex) of mobileColumns"
                                  :key="colIndex">

                                <div class="item-data ">
                                    {{ getCellValue(col, item[col]) }}
                                </div>

                            </div>-->
                        </div>

                        <div class="action-list uk-flex uk-flex-start uk-gap-s">
                            <div class="item-action uk-flex uk-flex-column uk-flex-middle uk-flex-center"
                                 v-if="$store.getters['user/profile'].isSuperAdmin || $store.getters['user/profile'].isExpert"
                                 @click="editItem(item)">
                                <i class="" uk-icon="icon:pencil;ratio: 0.8"></i>
                                <span class="">{{ translate('sgirot.general.edit') }}</span>
                            </div>
                            <div class="item-action uk-flex uk-flex-column uk-flex-middle uk-flex-center"
                                 v-if="$store.getters['user/profile'].isSuperAdmin"
                                 @click="promptDeleteItem(item)">
                                <i class="" uk-icon="icon:trash;ratio: 0.8"></i>
                                <span class="item-action__text">{{ translate('sgirot.general.delete') }}</span>
                            </div>
                            <div class="item-action uk-flex uk-flex-column uk-flex-middle uk-flex-center"
                                 @click="$router.push({name: 'project-itemId', params: {itemId : item.id}})">
                                <i class="" uk-icon="icon:info;ratio: 0.8"></i>
                                <span class="item-action__text">{{ translate('sgirot.general.view') }}</span>
                            </div>
                            <div class="item-action uk-flex uk-flex-column uk-flex-middle uk-flex-center"
                                 v-if="isSuperAdmin"
                                 @click.prevent()="copyProject(item.id)">
                                <i class="" uk-icon="icon: copy-page-reverse;ratio: 0.8"></i>
                                <span class="item-action__text">{{ translate('sgirot.project.copyProject') }}</span>
                            </div>
                        </div>
                    </template>

                    <template v-slot:cellContent="{item, col, colIndex}">

                        <div v-if="col == 'status'">
                            <status-pill :status="item[col]"
                                         style="cursor: pointer;"
                                         @click="editProjectStatus(item)"></status-pill>
                        </div>

                        <div v-if="col == 'price'">
                            <span v-if="item[col] && item[col] != 0">
                                {{ formatCellData(col, item[col]) }}
                            </span>

                            <span v-else class="uk-flex uk-flex-gap-s uk-flex-center uk-flex-middle">
                                <span class="col-warning" uk-icon="warning"></span>
                                {{ formatCellData(col, item[col]) }}
                            </span>

                        </div>

                        <div v-if="col == 'dueDate'">
                            <span v-if="item[col] && item[col] != 0">
                                {{ formatCellData(col, item[col]) }}
                            </span>

                            <span v-else class="uk-flex uk-flex-gap-s uk-flex-center uk-flex-middle">
                                <span class="col-warning" uk-icon="warning"></span>
                                {{ formatCellData(col, item[col]) }}
                            </span>

                        </div>


                        <span v-if=" ! ['status','price', 'dueDate'].includes(col)&& ! tableColumns[col].linkable">
                            {{ formatCellData(col, item[col]) }}
                        </span>

                        <a href="#" @click.prevent="$router.push({name: 'project-itemId', params: {itemId: item.id}})"
                           v-if="col !== 'status' &&  tableColumns[col].linkable">
                            {{ formatCellData(col, item[col]) }}
                        </a>

                    </template>

                </crud-list>
            </div>
        </div>

        <modal :show="isEditingItemStatus"
               class="edit-item-status-modal"
               :type="isMobile ? 'full' : 'regular'"
               :showFooter="false"
               :title="translate('sgirot.project.statusEditModal.title')">

          <div class="current-status uk-flex  uk-flex-middle uk-gap">
          </div>
            <div class="new-status uk-flex  uk-flex-middle uk-gap">
                <form-input type="select2"
                            v-if="currentStatusEditItem"
                            name="status"
                            v-model="currentStatusEditItemStatus"
                            @change="editProjectStatusFieldChangeHandler"
                            label="sgirot.project.fields.status"
                            placeholder="sgirot.project.fields.statusPlaceholder"
                            itemComponent="iconItem"
                            asyncListSource="project/status/simple-list"
                            :asyncListLive="false"
                            asyncDataTextFilterParamName="name"
                            :multiple="false"
                ></form-input>
            </div>
            <div class="cancel">
                <form-button type="error" icon="close" @click="cancelStatusEdit">{{translate('sgirot.project.statusEditModal.cancel')}}</form-button>
            </div>

        </modal>
    </div>

</template>

<script>
import CrudList from '@/client/applications/sgirot/components/crud/List.vue';
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";
import {useStore} from "vuex";
import asyncOperations from '@/client/extensions/composition/asyncOperations.js';
import StatusPill from '@/client/applications/sgirot/components/etc/StatusPill.vue';
import ApexCharts from 'apexcharts';
import {computed, nextTick, watchEffect} from 'vue';

export default {
    name: 'project-index',
    props: {
        noPriceOnly: {
            type: [String, Boolean],
            default: false
        },
        forceRefresh: {
            default: false,
        }
    },
    setup(props) {
        let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());
        let {meta}                                 = useMeta({
                                                                 title: useI18n().t('sgirot.project.mainTitle')
                                                             }).meta;
        return {asyncOps, asyncOpsReady, asyncStatus, meta};
    },
    middleware: {
        sgirotUser: {
            userTypes: [1]
        }
    },
    data() {
        return {
            lastScroll : false,
            currentStatusEditItem  : false,
            isEditingItemStatus      : false,
            commissionChart        : false,
            projectsChart          : false,
            showGraphsOnMobile     : false,
            isMobile               : false,
            entityType             : 'project',
            statuses               : {}, // async data
            rawExpertCommissionData: false,
            massStatusValue        : null,
            statusInputConf        : {
                name                        : 'status',
                type                        : 'Select2',
                placeholder                 : 'sgirot.project.fields.changeStatusAction',
                itemComponent               : 'iconItem',
                asyncListSource             : 'project/status/simple-list',
                asyncListLive               : false,
                asyncDataTextFilterParamName: 'name',
                multiple                    : false,
                validation                  : {
                    'required': {},
                },
                group                       : 'group3',
            },
            previouslyMounted      : false,
            graphLoading           : true,
            summaryData            : false,
            baseGraphOptions       : {
                series    : [{
                    name: '',
                    data: []
                }],
                chart     : {
                    height : 150,
                    type   : 'area',
                    toolbar: {
                        show: false,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                colors    : ['#716AAF', '#716AAF', '#9C27B0'],
                stroke    : {
                    curve: 'smooth'
                },

                xaxis  : {
                    type      : 'datetime',
                    labels    : {
                        show: false,
                    },
                    categories: []
                },
                legend : {
                    show: false,
                },
                tooltip: {
                    x: {
                        format: 'MMMM'
                    },
                },
            },
            asyncData              : {
                summaryData: {
                    target: 'project/summary',
                    data  : {
                        'dateRangeStart': computed(() => {
                            let d = new Date();
                            d.setFullYear(d.getFullYear() - 1);
                            d.setDate(1);
                            return d.toISOString().split('T')[0]
                        })
                    }
                },
                statuses   : 'project/status'
            },
        }
    },
    components: {
        CrudList, StatusPill
    },
    computed  : {
        currentStatusEditItemStatus : {
           get() {
               if ( ! this.currentStatusEditItem) {
                   return
               }

               let nonReactive = {...this.currentStatusEditItem};
               return nonReactive.status;
           },
            set(val) {
               this.updateCurrentEditProjectStatus(val);
            }
        },

        graphData() {
            if (!this.summaryData || !this.summaryData.items) {
                return {
                    data      : [],
                    categories: [],
                }
            }

            let result = {
                data      : [],
                categories: [],
            }

            let now = new Date(), current, currentTimeStamp;
            let currentMonth = now.getMonth() + 1;
            let currentYear = now.getFullYear();

            this.summaryData.items.map(item => {
                if (item.year > currentYear || (item.year == currentYear && item.month > currentMonth)) {
                   return true;
                }

                let dateString       = item.year + '-' + item.month + '-1';
                item.dueCommission   = parseInt(item.dueCommission);
                item.paidCommission  = parseInt(item.paidCommission);
                item.totalCommission = parseInt(item.totalCommission);
                result.data.push({...item, dateString});
                result.categories.push({month: item.month, year: item.year, day: 1, dateString});
            });




            return result;

        },
        expertCommissionData() {
            if ( ! this.rawExpertCommissionData || ! Array.isArray(this.rawExpertCommissionData)) {
                return false;
            }

            let data = {'totalCommission': 0, 'dueCommission': 0, 'paidCommission': 0}

            this.rawExpertCommissionData.forEach((month) => {
                data.totalCommission += month.totalCommission;
                data.dueCommission += month.dueCommission;
                data.paidCommission += month.paidCommission;
            });

            return data;
        },
        filterFields() {
            let all = {
                search        : {
                    name       : 'search',
                    label      : false,
                    placeholder: 'sgirot.expert.fields.listFilterSearchPlaceholder',
                    type       : 'text',
                },
                expertId      : {
                    name                        : 'expertId',
                    type                        : 'Select2',
                    placeholder                 : 'sgirot.project.fields.expertFilterPlaceHolder',
                    itemComponent               : 'iconItem',
                    asyncListSource             : 'expert/simple-list',
                    asyncListLive               : false,
                    asyncDataTextFilterParamName: 'name',
                    multiple                    : false,
                    validation                  : {
                        'required': {},
                    },
                    group                       : 'group3',
                },
                sourceId      : {
                    name                        : 'sourceId',
                    type                        : 'Select2',
                    placeholder                 : 'sgirot.project.fields.sourceFilterPlaceHolder',
                    itemComponent               : 'iconItem',
                    asyncListSource             : 'source/simple-list',
                    asyncListLive               : false,
                    asyncDataTextFilterParamName: 'name',
                    multiple                    : false,
                    validation                  : {
                        'required': {},
                    },
                    group                       : 'group3',
                },
                dateRangeStart: {
                    name       : 'dateRangeStart',
                    type       : 'sgirotDate',
                    style      : 'width: 145px', // avoid stupid display issue
                    placeholder: 'sgirot.project.fields.dateRangeStartFilterPlaceHolder',
                    group      : 'group3',
                },
                dateRangeEnd  : {
                    name       : 'dateRangeEnd',
                    type       : 'sgirotDate',
                    style      : 'width: 145px', // avoid stupid display issue
                    placeholder: 'sgirot.project.fields.dateRangeEndFilterPlaceHolder',
                    group      : 'group3',
                },
            };

            if (!this.isSuperAdmin) {
                if (this.isExpert) {
                    delete (all.expertId);
                    delete (all.sourceId);
                }

                if (this.isSource) {
                    delete (all.expertId);
                    delete (all.sourceId);
                }
            }

            return all;
        },
        mobileColumns() {
            return ['clientPhoneNumber', 'description', 'price', 'dueDate'];
        },
        tableColumns() {
            let cols = {
                'clientPhoneNumber': {
                    linkable         : true,
                    showInMobileBlock: true,
                    cellFormatter    : (val) => {
                        if ( ! val || val.trim() == '') {
                            return this.translate('sgirot.project.unknownClientPhoneNumber');
                        } else {
                            return val;
                        }
                    }
                },
                'description'      : {
                    showInMobileBlock: true,
                    linkable         : true,
                    cellFormatter    : (val) => {
                        if (!val) {
                            return this.translate('sgirot.project.projectWithoutDescription');
                        }

                        let length;

                        if (this.isMobile) {
                            length = 60;
                        } else {
                            length = 40;
                        }

                        if (val.length <= length) {
                            return val;
                        }
                        return val.substring(0, length) + '...';
                    }
                },
                'price'            : {
                    showInMobileBlock: true,
                    cellFormatter    : (val) => {
                        if (!val) {
                            return this.translate('sgirot.project.columnPriceUnknown');
                        }

                        return val;


                    }
                },
                'createdAt'        : {
                    cellFormatter: (val) => {
                        if (!val) {
                            return '-';
                        }
                        let date = new Date(val);
                        return date.toLocaleDateString('en-GB', {year: 'numeric', month: 'numeric', day: 'numeric'});
                    }
                },
                'expertName'       : {},
                'sourceName'       : {},
                'dueDate'          : {
                    cellFormatter: (val) => {
                        if (!val) {
                            return '-';
                        }

                        let date = new Date(val);
                        return date.toLocaleDateString('en-GB', {year: 'numeric', month: 'numeric', day: 'numeric'});
                    }
                },
                'percentCommission': {},
                'status'           : {
                    showInMobileBlock: true,
                    cellFormatter    : (val) => {
                        if (this.statuses && typeof this.statuses === 'object' && this.statuses.hasOwnProperty(val)) {
                            return this.safeTranslate(this.statuses[val].label);
                        }
                        return this.safeTranslate('sgirot.statuses.0');
                    }
                },
            };

            if ( ! this.isSuperAdmin) {
                if (this.isExpert) {
                    delete (cols.expertName);
                    delete (cols.sourceName);
                }

                if (this.isSource) {
                    delete (cols.sourceName);
                    delete (cols.expertName);
                }
            }

            return cols;

        },
        lastMonthSummaryData() {
            let result = {
                dueCommission : 0,
                totalComission: 0,
                totalCount    : 0,
            }
            if ( ! this.summaryData || !Array.isArray(this.summaryData.items) || this.summaryData.items.length < 1) {
                return result;
            }

           // go back in months, as long as that month is in the future (find the current month)
            let itemIndex = 1;
            let last = this.summaryData.items[this.summaryData.items.length - 1];
            let now = new Date();
            let currentMonth = now.getMonth() + 1;
            let currentYear = now.getFullYear();
            while (last.year > currentYear || (last.year == currentYear && last.month > currentMonth)) {
                itemIndex = itemIndex + 1;
                last = this.summaryData.items[this.summaryData.items.length - itemIndex];
            }

            result.totalCommission = last.totalCommission;
            result.dueCommission   = last.dueCommission;
            result.totalCount      = last.totalCount;

            return result;
        },
        graphsDynamicStyle() {
            let result = {
                'min-height': '200px',
            };

            if (this.asyncOps.asyncStatus.asyncDataLoading) {
                result.display       = 'none';
                result['min-height'] = 0;
            }

            if (this.isMobile && !this.showGraphsOnMobile) {
                result.display       = 'none';
                result['min-height'] = 0;
            }
            return result;
        }
    },
    methods   : {
        async copyProject(projectId) {
            this.$store.commit('ui/showGlobalSpinner');
            let result = await this.asyncOps.asyncCall('project/' + projectId + '/duplicate', {}, {method: 'post'});

            if (result.isError) {
                this.$s.ui.notification(this.translate('core.errorOccurredGeneric'), 'error');
            } else {
                this.$s.ui.notification(this.translate('core.createdSuccess'), 'success');
            }
            setTimeout(async () => {
                await this.$refs.crudList.refreshAsyncData();
                this.$store.commit('ui/showGlobalSpinner', false);
            }, 500);
        },
        async massSetStatus(items) {
            let patchData = items.map(item => {
                return {id: item.id, status: this.massStatusValue};
            });

            this.$store.commit('ui/showGlobalSpinner');

            let result = await this.asyncOps.asyncCall('project', {items: patchData}, {method: 'patch'});

            if (result.isError) {
                this.$s.ui.notification(this.translate('core.errorOccurredGeneric'), 'error');
            } else {
                this.$s.ui.notification(this.translate('sgirot.general.actionSuccessful'), 'success');
            }

            this.massStatusValue = null;
            await this.$refs.crudList.refreshAsyncData();
            await this.handleListFiltersUpdated(this.$refs.crudList.getItemFilters())
            this.$store.commit('ui/showGlobalSpinner', false);


        },
        async promptDeleteItems(items) {
            this.$s.ui.modal.confirm('sgirot.general.massDeleteConfirmText').then(result => {
                this.massDeleteItems(items);
            }).catch(result => {
                //
            });
        },
        async massDeleteItems(items) {
            let ids = items.map(item => item.id);

            this.$store.commit('ui/showGlobalSpinner');
            let result = await this.asyncOps.asyncCall('project', {ids}, {method: 'delete'});

            if (result.isError) {
                this.$s.ui.notification(this.translate('core.errorOccurredGeneric'), 'error');
            } else {
                this.$s.ui.notification(this.translate('sgirot.general.massDeleteSuccess'), 'success');
            }

            await this.$refs.crudList.refreshAsyncData();
            this.$store.commit('ui/showGlobalSpinner', false);
        },
        formatCellData(col, value) {
            if (this.tableColumns[col] && this.tableColumns[col].cellFormatter) {
                return this.tableColumns[col].cellFormatter(value);
            }
            return value;
        },
        showTodaysProjects() {
            let today = new Date();
            let offset;
            today.setHours(0, 0, 0, 0);
            offset = today.getTimezoneOffset();
            today  = new Date(today.getTime() - (offset * 60 * 1000));
            today  = today.toISOString().split('T')[0];

            this.$refs.crudList.setFilters({
                                               "dateRangeStart": today,
                                               "dateRangeEnd"  : today,
                                           });
        },
        showTomorrowsProjects() {
            let today    = new Date();
            let tomorrow = new Date(today);
            let offset;
            tomorrow.setDate(tomorrow.getDate() + 1)
            tomorrow.setHours(0, 0, 0, 0);
            offset   = tomorrow.getTimezoneOffset();
            tomorrow = new Date(tomorrow.getTime() - (offset * 60 * 1000));
            tomorrow = tomorrow.toISOString().split('T')[0];

            this.$refs.crudList.setFilters({
                                               "dateRangeStart": tomorrow,
                                               "dateRangeEnd"  : tomorrow,
                                           });
        },
        clearFilters() {
            this.$refs.crudList.setFilters({}, true);
        },
        updateTotalProjectGraph() {
            let options    = {...this.baseGraphOptions};
            let data       = [];
            let categories = [];

            this.graphData.data.map(item => {
                data.push(item.totalCount);
                categories.push(item.dateString);
            });

            options.series[0].name   = this.translate('sgirot.graphs.totalProjects');
            options.series[0].data   = data;
            options.xaxis.categories = categories

            var chart = new ApexCharts(this.$refs.totalProjectsGraph, options);
            chart.render();


            this.projectsChart = chart;
        },
        updateCommissionProjectGraph() {
            let options    = {...this.baseGraphOptions};
            let data       = [];
            let categories = [];

            this.graphData.data.map(item => {
                data.push(item.dueCommission);
                categories.push(item.dateString);
            });

            options.series[0].name   = this.translate('sgirot.graphs.totalCommission');
            options.series[0].data   = data;
            options.xaxis.categories = categories

            var chart = new ApexCharts(this.$refs.totalCommissionGraph, options);
            chart.render();

            this.commissionChart = chart;
        },
        updateGraphsUI() {
            if (utilities.isSSR()) {
                return;
            }


            try {
                this.projectsChart.destroy();
            } catch (e) {

            }

            try {
                this.commissionChart.destroy();
            } catch (e) {

            }
            this.updateTotalProjectGraph();
            this.updateCommissionProjectGraph();
        },
        async handleListFiltersUpdated(itemFilters) {

            let sourceId = itemFilters.sourceId || 0;
            let expertId = itemFilters.expertId || 0;
            if (this.isSuperAdmin ) {
                // if filters cleared, clear data
                if ( ! sourceId && ! expertId) {
                    return this.rawExpertCommissionData = false;
                } else {
                    return this.setCommissionDataFromApi (
                        expertId,
                        (itemFilters.dateRangeStart || '1970-01-01'),
                        (itemFilters.dateRangeEnd || '9999-01-01'),
                        sourceId,
                    );
                }
            }

            // sources can not see expert summary
            if (this.isExpert) {
                // for expert, show the data always, but only for the logged in expert
                return this.setCommissionDataFromApi (
                    this.$store.getters['user/profile'].expertId,
                    (itemFilters.dateRangeStart || '1970-01-01'),
                    (itemFilters.dateRangeEnd || '9999-01-01'),
                );
            }

            if (this.isSource) {
                // for expert, show the data always, but only for the logged in expert
                return this.setCommissionDataFromApi (
                    null,
                    (itemFilters.dateRangeStart || '1970-01-01'),
                    (itemFilters.dateRangeEnd || '9999-01-01'),
                    this.$store.getters['user/profile'].sourceId,
                );
            }



        },
        async setCommissionDataFromApi (expertId = 0, dateRangeStart = '1970-01-01', dateRangeEnd = '9999-01-01', sourceId = 0) {
            let data = {
                expertId      : expertId,
                sourceId      : sourceId,
                dateRangeStart: dateRangeStart,
                dateRangeEnd  : dateRangeEnd,
            }
            let result;

            try {
                result = await this.asyncOps.asyncCall('project/summary', data, {method: 'get'});
            } catch (e) {
                console.log(e);
            }

            this.rawExpertCommissionData = result.data.items;
        },
        handleListDataUpdated(arg) {

        },
        triggerShowGraphsOnMobile() {
            this.showGraphsOnMobile = true;

            if (this.projectsChart) {
                this.projectsChart.destroy();
            }

            if (this.commissionChart) {
                this.commissionChart.destroy();
            }
            setTimeout(() => {
                this.updateGraphsUI();
            }, 50);
        },
        getCellValue(col, value) {
            if (!this.tableColumns.hasOwnProperty(col)) {
                return value;
            }

            if (typeof this.tableColumns[col].cellFormatter === 'function') {
                return this.tableColumns[col].cellFormatter(value);
            }

            return value;
        },
        showItem(item) {
            this.$router.push({name: this.entityType + '-itemId', params: {itemId: item.id}});
        },
        editItem(item) {
            this.$router.push({name: this.entityType + '-edit-itemId', params: {itemId: item.id}});
        },
        editProjectStatus (item) {
          this.currentStatusEditItem = item;
          this.isEditingItemStatus = true

          //  //  this.$refs.crudList.updateItem(10128, {'price': 9000})
        },
        async updateCurrentEditProjectStatus(val) {
            let patchData = [{
                id: this.currentStatusEditItem.id,
                status: val
            }]

            this.$store.commit('ui/showGlobalSpinner');

            let result = await this.asyncOps.asyncCall('project', {items: patchData}, {method: 'patch'});

            if ( result.isError) {
                this.$s.ui.notification(this.translate('core.errorOccurredGeneric'), 'error');
            }

            this.$store.commit('ui/showGlobalSpinner', false);
            this.updateLocalProject(this.currentStatusEditItem.id, {status : val});
            this.isEditingItemStatus = false;
            this.currentStatusEditItem = false;
        },
        cancelStatusEdit () {
            this.isEditingItemStatus = false;
            this.currentStatusEditItem = false;
        },
        updateLocalProject (id, updates) {
           return  this.$refs.crudList.updateLocalItem(id, updates);
        },
        async deleteItem(item) {
            let result = await this.asyncOps.asyncCall(this.entityType + '/' + item.id, {}, {'method': 'delete'});

            if (result.isError) {
                this.$s.ui.notification(this.translate('sgirot.general.actionFailed'), 'error');
            } else {
                this.$s.ui.notification(this.translate('sgirot.general.actionSuccessful'), 'success');
            }

            this.refreshAsyncData();
        },
        promptDeleteItem(item) {
            this.$s.ui.modal.confirm('sgirot.general.deleteConfirmText').then(result => {
                this.deleteItem(item);
            }).catch(result => {
                //
            });
        },
        handleOnMountScroll () {
            this.$refs.crudList.refresh().then(() => {
               wait(200).then(() => {
                   window && window.scroll( {top: this.lastScroll});
               });
            });
        },
        parseProjectToCsvRow(item) {
            let values = [];
            let itemSourceName = typeof item.sourceName === 'string' ? item.sourceName.trim() : '-';
            let clientPhoneNumber  = typeof item.clientPhoneNumber === 'string' ? item.clientPhoneNumber.trim() : item.clientPhoneNumber;


            values.push(item.id);
            values.push(typeof item.description === 'string' ? item.description.replaceAll(',', ' ') : '');
            values.push(item.dueDate);
            values.push(item.expertName);
            values.push(item.percentCommission);
            values.push(item.price);
            values.push(itemSourceName);
            values.push(clientPhoneNumber);
            values.push(item.status);
            values.push(this.translate('sgirot.statuses.'+item.status).replaceAll(',', ' '));
            return values.join(",");

        },
        exportItemsCsv(items) {
            if (utilities.isSSR()) {
                return true;
            }

            let strPrefix = "data:text/csv;charset=utf-8,";
            let str = "\uFEFF"; // we need to encode this first, for microsoft excel to "auto import" the utf8 csv correctly

            // add headers, then rows
            str += 'ID,Description,DueDate,ExpertName,PercentCommission,Price,SourceName,ClientPhoneNumber,StatusCode,StatusLabel\n';
            items.forEach((item) => {
                let row = this.parseProjectToCsvRow(item);
                str += row + "\r\n";
            });

            let encodedUri = encodeURIComponent(str);
            let link = document.createElement("a");
            link.setAttribute("href", strPrefix + encodedUri);
            link.setAttribute("download", "projects.csv");
            document.body.appendChild(link);
            link.click();

        }
    },
    mounted() {

        if (this.noPriceOnly) {
            this.$refs.crudList.setFilters( {noPrice : '1'}, true);
        }

        if (this.isExpert) {
            this.setCommissionDataFromApi(this.$store.getters['user/profile'].expertId);
        }

        if (this.isSource) {
            this.setCommissionDataFromApi(0, '1970-01-01', '9999-01-01', this.$store.getters['user/profile'].sourceId);
        }

        if (window && document.body.clientWidth < 760) {
            this.isMobile = true;
        }
    },

    beforeRouteLeave() {
        if (window) {
            this.lastScroll = document.documentElement.scrollTop;
        }
    },
    beforeRouteEnter(to, from, next) {
        next( vm => {
            if (vm.forceRefresh) {
                vm.handleOnMountScroll()
            }
        });
    },
    watch : {
        summaryData: function () {
            setTimeout(() => {

                if (!this.showGraphsOnMobile || this.showGraphsOnMobile) {
                    this.updateGraphsUI();
                }

            }, 550);
        },
        noPriceOnly(newVal) {

            if ( ! newVal) {
                this.$refs.crudList.setFilters({noPrice: '0'}, false);
            }
            this.$refs.crudList.setFilters( {noPrice : '1'}, true);
            if (window) {
               const y = this.$refs.crudList.$el.getBoundingClientRect().top + window.scrollY;
               utilities.wait(100).then( () => {
                   window.scrollTo({top: y, behavior: 'smooth'});
               });

            }
        },


    },
    layout: 'dashboard',
}
</script>

<style lang="scss" scoped>
.header-action {
    padding       : 6px 12px;
    border-radius : 12px;
    background    : var(--global-primary-background);
    color         : var(--global-background);
    cursor        : pointer;
    transition    : 150ms ease;

    &:hover {
        opacity : 0.8;
    }


}

.expert-commission-summary {
    font-size : var(--global-font-size);
}

.col-warning {
    border-radius: 100%;
   // background: var(--global-danger-background);
    color: var(--global-danger-background);

}
</style>

<style lang="scss">
.edit-item-status-modal .select2-dropdown {
    min-height: 335px;

    .inner {
        min-height: 320px!important;
        max-height: 320px!important;
    }


}
</style>
